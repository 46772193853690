<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>User Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >First Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="First Name"
                      v-model="user.firstName"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.user.firstName.$errors)
                      "
                      @blur="v$.user.firstName.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Last Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Last Name"
                      v-model="user.lastName"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.user.lastName.$errors)
                      "
                      @blur="v$.user.lastName.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Email</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="email"
                      v-model="user.email"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.user.email.$errors)
                      "
                      @blur="v$.user.email.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Mobile</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="mobile"
                      v-model="user.mobile"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.user.mobile.$errors)
                      "
                      @blur="v$.user.mobile.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >User Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="User Type"
                      :items="userTypes"
                      item-text="value"
                      item-value="key"
                      v-model="user.type"
                      :error-messages="
                        getFieldValidationErrors(v$.user.type.$errors)
                      "
                      @blur="v$.user.type.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >User Password</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Password"
                      v-model="user.password"
                      :error-messages="
                        getFieldValidationErrors(v$.user.password.$errors)
                      "
                      @input="v$.user.password.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Address Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="12"
                    class="py-0"
                  >
                    <vuetify-google-autocomplete
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.addressAutocompleteModel"
                      id="editUserAddress"
                      v-on:placechanged="getAddressData"
                      country="za"
                    >
                    </vuetify-google-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 1</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 1"
                      v-model="user.address.addressLine1"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.address.addressLine1.$errors
                        )
                      "
                      @blur="v$.user.address.addressLine1.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 2</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 2"
                      v-model="user.address.addressLine2"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.address.addressLine2.$errors
                        )
                      "
                      @blur="v$.user.address.addressLine2.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 3</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 3"
                      v-model="user.address.addressLine3"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.address.addressLine3.$errors
                        )
                      "
                      @blur="v$.user.address.addressLine3.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Surburb</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.address.surburb"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.address.surburb.$errors
                        )
                      "
                      @blur="v$.user.address.surburb.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >City</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.address.city"
                      :error-messages="
                        getFieldValidationErrors(v$.user.address.city.$errors)
                      "
                      @blur="v$.user.address.city.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Province</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.address.province"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.address.province.$errors
                        )
                      "
                      @blur="v$.user.address.province.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Latitude</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.address.latitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Longitude</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the user address..."
                      v-model="user.address.longitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address Type"
                      :items="addressTypes"
                      item-text="value"
                      item-value="key"
                      v-model="user.address.type"
                      :error-messages="
                        getFieldValidationErrors(v$.user.address.type.$errors)
                      "
                      @input="v$.user.address.type.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      ><span style="color: transparent">.</span></label
                    >
                    <v-btn
                      @click="addressAutocomplete = true"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-outline-primary py-3 px-6 rounded-sm me-2"
                      color="white"
                      >Search Address Details</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Contact Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="2" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Country Code</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Country code"
                      v-model="user.contact.countryCode"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.user.contact.countryCode.$errors
                        )
                      "
                      @blur="v$.user.contact.countryCode.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Contact Number</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number"
                      v-model="user.contact.number"
                      :error-messages="
                        getFieldValidationErrors(v$.user.contact.number.$errors)
                      "
                      @blur="v$.user.contact.number.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Contact Number Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number Type"
                      :items="contactTypes"
                      item-text="value"
                      item-value="key"
                      v-model="user.contact.type"
                      :error-messages="
                        getFieldValidationErrors(v$.user.contact.type.$errors)
                      "
                      @blur="v$.user.contact.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Create</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "users-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { requiredIfFoo: requiredIf(!this.user.mobile) },
        mobile: { requiredIfFoo: requiredIf(!this.user.email) },
        type: { required },
        address: {
          type: { required },
          addressLine1: { required },
          addressLine2: { required },
          addressLine3: { required },
          surburb: { required },
          city: { required },
          province: { required },
        },
        contact: {
          type: { required },
          countryCode: { required },
          number: { required },
        },
        password: { requiredIfFoo: requiredIf(this.user.mobile) },
      },
    };
  },
  props: {},
  data() {
    return {
      addressAutocomplete: true,
      addressTypes: [],
      contactTypes: [],
      submitButton: {
        isLoading: false,
      },
      user: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        emailVerifiedAt: "",
        type: "",
        status: "",
        lastLoginAt: "",
        occupation: "",
        profileImageUri: "",
        addressAutocompleteModel: null,
        address: {
          id: null,
          type: "",
          longitude: "",
          latitude: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          surburb: "",
          city: "",
          province: "",
          code: "",
        },
        contact: {
          id: null,
          type: "",
          countryCode: "+27",
          number: "",
        },
        password: "",
      },
      userTypes: [],
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    getAddressData(addressData, placeResultData) {
      this.setAddressDetailsFromGooglePlacesApi(
        this.user.address,
        addressData,
        placeResultData
      );
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const userObject = {
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        type: this.user.type,
        image_uri: this.user.profileImageUri,
      };

      if (this.user.email) {
        userObject.email = this.user.email;
      }

      if (this.user.mobile) {
        userObject.mobile = this.user.mobile;
      }

      if (this.user.password) {
        userObject.password = this.user.password;
      }

      const addressObject = {
        id: this.user.address.id || null,
        type: this.user.address.type,
        address_line_1: this.user.address.addressLine1,
        address_line_2: this.user.address.addressLine2,
        address_line_3: this.user.address.addressLine3,
        surburb: this.user.address.surburb,
        city: this.user.address.city,
        province: this.user.address.province,
        latitude: this.user.address.latitude.toString(),
        longitude: this.user.address.longitude.toString(),
      };

      const contactObject = {
        id: this.user.contact.id || null,
        country_code: this.user.contact.countryCode,
        number: this.user.contact.number,
        type: this.user.contact.type,
      };

      let baseUri = "/users";
      this.$http
        .post(baseUri, userObject, this.sessionStore.getHttpConfig)
        .then(async (result) => {
          const promises = [];
          baseUri = `${baseUri}/${result.data.user_id}`;

          if (addressObject.id != null) {
            promises.push(
              this.$http.post(
                `${baseUri}/address-details`,
                addressObject,
                this.sessionStore.getHttpConfig
              )
            );
          } else {
            promises.push(
              this.$http.post(
                `${baseUri}/address-details`,
                addressObject,
                this.sessionStore.getHttpConfig
              )
            );
          }

          if (addressObject.id != null) {
            promises.push(
              this.$http.post(
                `${baseUri}/contact-details`,
                contactObject,
                this.sessionStore.getHttpConfig
              )
            );
          } else {
            promises.push(
              this.$http.post(
                `${baseUri}/contact-details`,
                contactObject,
                this.sessionStore.getHttpConfig
              )
            );
          }

          return Promise.all(promises).then(() => {
            this.$notify.success("User created successfully!");
            this.$router.push("/users");
          });
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while creating the user. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.addressAutocomplete = true;
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    if (
      this.sessionStore.user.roles &&
      this.sessionStore.user.roles.includes("super_admin", "admin")
    ) {
      this.fetchStaticDataByGrouping("userTypes")
        .then((result) => {
          this.userTypes.splice(0);

          for (let i = 0; i < result.data.data.length; i += 1) {
            this.userTypes.push(result.data.data[i]);
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while loading user types. Please try again later or contact support."
          );
        });
    } else if (
      this.sessionStore.user.roles &&
      this.sessionStore.user.roles.includes("warehouse_manager")
    ) {
      this.userTypes.splice(0);
      this.userTypes.push({
        key: "driver",
        value: "Driver",
      });
      this.userTypes.push({
        key: "packer",
        value: "Packer",
      });
      this.userTypes.push({
        key: "shop_owner",
        value: "Shop Owner",
      });
    } else if (
      this.sessionStore.user.roles &&
      this.sessionStore.user.roles.includes("agent")
    ) {
      this.userTypes.splice(0);
      this.userTypes.push({
        key: "shop_owner",
        value: "Shop Owner",
      });
    }

    this.fetchStaticDataByGrouping("addressTypes")
      .then((result) => {
        this.addressTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.addressTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading address types. Please try again later or contact support."
        );
        console.error("Error while fetching address types: ", error);
      });

    this.fetchStaticDataByGrouping("contactTypes")
      .then((result) => {
        this.contactTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.contactTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading contact types. Please try again later or contact support."
        );
        console.error("Error while fetching contact types: ", error);
      });
  },
};
</script>
